import { Route, Switch } from 'react-router-dom';
import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';
import { DefaultRedirect } from './default-redirect';
import { ErrorPage } from 'pages/error';
import { PrivateRoute } from 'components/private-route';
import React from 'react';
import { ServiceRequests } from 'pages/service-requests';
import { DetailsPage } from 'pages/details';
import { Deeplink } from 'components/deeplink';
import { CancelServiceRequestModal } from 'pages/service-requests/cancelServiceRequestModal';

export const Router: React.FC = (): JSX.Element => (
  <AppContentWrapper>
    <Switch>
      <PrivateRoute component={ServiceRequests} path="/service-requests" exact />
      <Route component={ErrorPage} path="/error" />
      <Route path="/service-requests/new" component={ServiceRequests} exact />
      <Route path="/service-requests/error" component={ServiceRequests} exact />
      <Route path="/service-requests/:requestId/view" component={DetailsPage} exact />
      <Route path="/service-requests/:requestId/cancel" component={CancelServiceRequestModal} exact />
      <Route path="/deeplink/:params" component={Deeplink} exact />
      <Route component={DefaultRedirect} />
    </Switch>
  </AppContentWrapper>
);
