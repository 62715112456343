import { useSteps } from 'hooks/use-steps.hook';
import { ScheduleRequestFormValues } from 'pages/details/schedule-request-form/schedule-request-form.hooks';
import { getHoursAndMinutes } from 'hooks/use-schedule-time-options.hook';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateServiceRequest,
  updateSetScheduleServiceRequest,
  updateShowAttachment,
  updateShowFloorPlanPinner,
} from 'store/serviceRequests/actions';
import {
  selectShowAttachment,
  selectShowFloorPlanPinner,
  selectShowScheduleServiceRequest,
} from 'store/serviceRequests/selectors';
import { useFeedAvailability } from '../../../hooks/use-feed-availability.hook';
import { ServiceRequestFrequencyEnum } from 'store/serviceRequests/enums';
import { openScheduleStep as trackSchedule } from 'utils/trackingUtils';

export enum RequestDetailsSteps {
  DETAILS = 'details',
  SCHEDULE = 'schedule',
  FEED = 'feed',
  ATTACHMENT = 'attachment',
  FLOOR_PLAN = 'floor_plan',
}

export const useDetailsSteps = () => {
  const isFeedStepAvailable = useFeedAvailability();
  const isScheduleActive = useSelector(selectShowScheduleServiceRequest);
  const isFloorPlanActive = useSelector(selectShowFloorPlanPinner);
  const { attachmentOpened: isAttachmentActive } = { ...useSelector(selectShowAttachment) };
  const steps = [
    RequestDetailsSteps.DETAILS,
    isScheduleActive && RequestDetailsSteps.SCHEDULE,
    isFeedStepAvailable && RequestDetailsSteps.FEED,
    isAttachmentActive && RequestDetailsSteps.ATTACHMENT,
    isFloorPlanActive && RequestDetailsSteps.FLOOR_PLAN,
  ].filter(Boolean);
  const { step, index, openNextStep, openPreviousStep, openStepByIndex } = useSteps(steps, RequestDetailsSteps.DETAILS);
  return {
    index,
    isFeedOpened: step === RequestDetailsSteps.FEED,
    isDetailsOpened: step === RequestDetailsSteps.DETAILS,
    isScheduleOpened: step === RequestDetailsSteps.SCHEDULE,
    isAttachmentOpened: step === RequestDetailsSteps.ATTACHMENT,
    isFloorPlanOpened: step === RequestDetailsSteps.FLOOR_PLAN,
    openNextStep,
    openPreviousStep,
    openStepByIndex,
    steps,
  };
};

export const useScheduleStep = (
  isScheduleOpened: boolean,
  steps: RequestDetailsSteps[],
  openStepByIndex: (stepIndex: number) => void,
) => {
  const dispatch = useDispatch();
  const detailsIndex = steps.findIndex(step => step === RequestDetailsSteps.DETAILS);
  useEffect(() => {
    const scheduleIndex = steps.findIndex(step => step === RequestDetailsSteps.SCHEDULE);
    if (scheduleIndex > 0) {
      openStepByIndex(scheduleIndex);
    }
  }, [openStepByIndex, steps]);
  const openScheduleStep = useCallback(() => {
    trackSchedule();
    dispatch(updateSetScheduleServiceRequest(true));
  }, [dispatch]);

  const closeScheduleStep = useCallback(() => {
    if (isScheduleOpened) {
      dispatch(updateSetScheduleServiceRequest(false));
      openStepByIndex(detailsIndex);
    }
  }, [detailsIndex, dispatch, isScheduleOpened, openStepByIndex]);

  return {
    openScheduleStep,
    closeScheduleStep,
  };
};

export const useAttachmentStep = (
  isAttachmentOpened: boolean,
  steps: RequestDetailsSteps[],
  openStepByIndex: (stepIndex: number) => void,
) => {
  const dispatch = useDispatch();
  const detailsIndex = steps.findIndex(step => step === RequestDetailsSteps.DETAILS);
  useEffect(() => {
    const attachmentIndex = steps.findIndex(step => step === RequestDetailsSteps.ATTACHMENT);
    if (attachmentIndex > 0) {
      openStepByIndex(attachmentIndex);
    }
  }, [openStepByIndex, steps]);
  const openAttachmentStep = useCallback(
    (ref, fileName) => {
      dispatch(updateShowAttachment({ attachmentOpened: true, attachmentRef: ref, attachmentName: fileName }));
    },
    [dispatch],
  );
  const closeAttachmentStep = useCallback(() => {
    if (isAttachmentOpened) {
      dispatch(updateShowAttachment({ attachmentOpened: false, attachmentRef: null, attachmentName: null }));
      openStepByIndex(detailsIndex);
    }
  }, [detailsIndex, dispatch, isAttachmentOpened, openStepByIndex]);
  return {
    openAttachmentStep,
    closeAttachmentStep,
  };
};

export const useFloorPlanStep = (
  isFloorPlanOpened: boolean,
  steps: RequestDetailsSteps[],
  openStepByIndex: (stepIndex: number) => void,
) => {
  const dispatch = useDispatch();
  const detailsIndex = steps.findIndex(step => step === RequestDetailsSteps.DETAILS);
  useEffect(() => {
    const floorPlanIndex = steps.findIndex(step => step === RequestDetailsSteps.FLOOR_PLAN);
    if (floorPlanIndex > 0) {
      openStepByIndex(floorPlanIndex);
    }
  }, [openStepByIndex, steps]);
  const openFloorPlanStep = useCallback(() => {
    dispatch(updateShowFloorPlanPinner(true));
  }, [dispatch]);
  const closeFloorPlanStep = useCallback(() => {
    if (isFloorPlanOpened) {
      dispatch(updateShowFloorPlanPinner(false));
      openStepByIndex(detailsIndex);
    }
  }, [detailsIndex, dispatch, isFloorPlanOpened, openStepByIndex]);
  return {
    openFloorPlanStep,
    closeFloorPlanStep,
  };
};
export const getServiceRequestSchedulePayload = (values: ScheduleRequestFormValues): Partial<ServiceRequestPayload> => {
  const startDate = new Date(values.startDate);
  const { hours, minutes } = getHoursAndMinutes(values.startTime);
  startDate.setHours(parseInt(hours, 10));
  startDate.setMinutes(parseInt(minutes, 10));
  return {
    schedule: {
      frequency: ServiceRequestFrequencyEnum.ONCE,
      start_date: startDate,
    },
  };
};

export const useSaveScheduleData = (closeScheduleStep: () => void) => {
  const dispatch = useDispatch();

  const saveScheduleDataAndCloseStep = useCallback(
    (values: ScheduleRequestFormValues) => {
      const schedulePayload = getServiceRequestSchedulePayload(values);
      dispatch(
        updateServiceRequest.request({
          request: schedulePayload,
          closeSwipeCallback: closeScheduleStep,
        }),
      );
    },
    [closeScheduleStep, dispatch],
  );

  return { saveScheduleDataAndCloseStep };
};
