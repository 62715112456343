import { useParams, Redirect } from 'react-router-dom';
import { ROOT_PATH } from 'utils/constants';

export const Deeplink = (): JSX.Element | null => {
  const { params } = useParams<{ params: string }>();
  const base64Params = atob(params);
  const parsedParams = JSON.parse(base64Params);

  if (parsedParams?.action == null) {
    return <Redirect to={`${ROOT_PATH}`} />;
  }
  return null;
};
